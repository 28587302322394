import {
    AccessLevel,
    ArtifactId,
    Balance,
    CampaignType,
    ClanParticipationState, DelayedChestDto, ForceDescription, ForceShortDescription,
    GiftNews, HammerDto,
    HistoryDto,
    InterfaceMode,
    Lang, LogType, MetaDataType, Notif, NotifType,
    OkGroupCampaignDto, PaymentsDto, PayType,
    Player,
    PlayerInfo,
    PlayerMarker,
    PlayerState, Quality, ReferralBonus, RestoreOptions,
    StatusDto, WheelStatusDto, YaPromocodeResult
} from './types';
import http from '../../axios';
import {Amount} from "@/model/amount";
import ListenerManager from "@/managers/listener-manager";
import {ResourceType} from "@/store/place/types";
import {Creature, WarriorClass} from "@/store/level/types";
import {Version} from "@/constants/version";
import PlaceStore from "@/store/place";
import {Settings} from "@/constants/settings";
import {PlayerAction} from "@/store/profile/types";
import {AppmetricaService} from "@/services/appmetrica-service";
import OnceCaller from "@/utils/once-caller";
import TaskUtils from "@/utils/task-utils";
import {Task} from "@/store/task/types";
import {HSessionUtils} from "@/utils/h-session-utils";
import {CardState} from "@/store/card/types";
import {DomainUtils} from "@/utils/domain-utils";
import {Sale} from "@/store/sale/types";

export default class PlayerStore {

    private static state: PlayerState = {
        player: undefined,

        campaignTypes: [],

        /**
         * Delta between server and client time
         */
        timeDeltaMs: 0,

        gameStart: 0,

        /**
         * There are too many artifact pictures, so we fetch only artifact ids we really need now, and load only these images
         */
        artifactIdsToLoad: [],

        /**
         * artifactImageMaxStair is the max stair artifact loaded. If player.stair becomes more than artifactImageMaxStair,
         * then its necessary to load next pictures.
         */
        artifactImageMaxStair: 0,

        /**
         * indicates if need download more artifacts (happens when player stair grows)
         */
        needDownload: false,

        clientUuid: '',

        prompt: undefined,

        header: '',
        footer: '',
        admined: false,
        version: 0,

        remindCampaignRules: true,
        unseenCampaign: undefined,

        wheelStatus:undefined,
        wheelAutoRotation:false,

        quality: undefined,

        historyCategory: undefined,

        forceDescriptions: undefined,
        referralBonus: undefined,
        hammerDto: undefined,
        cardState: undefined,
        sale: undefined
    }

    private static handlePlayerInfo(playerInfoObj: any, updateLang: boolean): void {
        if (playerInfoObj) {
            let playerInfo = Object.assign(new PlayerInfo(), playerInfoObj);
            PlayerStore.SET_HEADER(playerInfo.header);
            PlayerStore.SET_FOOTER(playerInfo.footer);
            PlayerStore.state.quality = playerInfo.quality;
            PlayerStore.SET_CAMPAIGN_TYPES(playerInfo.campaignTypes);
            //PlayerStore.SET_FORCE_DESCRIPTIONS(playerInfo.forceDescriptions);
            PlayerStore.SET_UNSEEN_CAMPAIGN(playerInfo.optionalUnseenCampaign);
            PlayerStore.SET_REFERRAL_BONUS(playerInfo.referralBonus);
            PlayerStore.SET_HAMMERS_COUNT(playerInfo.hammerDto.currenCount);
            PlayerStore.SET_MAX_HAMMERS_COUNT(playerInfo.hammerDto.maxCount);
            PlayerStore.SET_CARD_STATE(playerInfo.cardState);
            PlayerStore.SET_SALE(playerInfo.sale);
            if (playerInfo.player) {
                const clientTimeMs: number = Date.now();
                const timeDeltaMs: number = playerInfo.serverTimeMs - clientTimeMs;
                PlayerStore.SET_TIME_DELTA_MS(timeDeltaMs);
                PlayerStore.SET_CLIENT_UUID(playerInfo.clientUuid);
                PlayerStore.SET_PLAYER(playerInfo.player);
                PlayerStore.SET_ADMINED(playerInfo.admined);
                PlayerStore.SET_VERSION(playerInfo.version);

                let artifactIdsToLoad: ArtifactId[] = [];
                for (let obj of playerInfo.artifactIdsToLoad) {
                    let ai = Object.assign(new ArtifactId(), obj);
                    artifactIdsToLoad.push(ai);
                }

                PlaceStore.SET_TILES(playerInfo.tiles);

                PlayerStore.SET_ARTIFACT_IDS(artifactIdsToLoad, playerInfo.artifactImageMaxStair);
                PlayerStore.SET_NEED_DOWNLOAD_IMAGES(true);

                if (updateLang) {
                    if (playerInfo.player.lang === Lang.EN) {
                        Settings.setEn();
                    } else {
                        Settings.setRu();
                    }
                }
            }
        }
    }

    private static doSetPlayer(player: Player): void {
        PlayerStore.state.player = player;

        if (PlayerStore.state.player.creatures) {
            PlayerStore.state.player.creatures = PlayerStore.state.player.creatures.sort((a: Creature, b: Creature) => {
                return b.id - a.id;
            });
        }

        if (player.prompt) {
            PlayerStore.state.prompt = player.prompt;
        }
    }

    private static doSetPlayerSkipCreatures(player: Player): void {
        if (player.creatures && player.creatures.length > 0) {
            this.doSetPlayer(player);
        } else {
            if (PlayerStore.state.player) {
                let crs = PlayerStore.state.player.creatures;
                this.doSetPlayer(player);
                PlayerStore.state.player.creatures = crs;
            } else {
                this.doSetPlayer(player);
            }
        }
    }

    //
    //
    // ================= Getters =================
    //
    //

    public static getTaskById(taskId: string): Task | undefined {
        if (!PlayerStore.state.player || !PlayerStore.state.player.tasks) {
            return undefined;
        }
        return PlayerStore.state.player!.tasks.find(t => t.taskId === taskId);
    }

    public static isAdmined() {
        return PlayerStore.state.admined;
    }

    public static needRemindCampaignRules() {
        return  PlayerStore.state.remindCampaignRules;
    }

    public static setRemindCampaignRules( remindCampaignRules: boolean) {
        PlayerStore.state.remindCampaignRules = remindCampaignRules;
    }

    public static getVersion(): number {
        return PlayerStore.state.version;
    }

    public static hasModerAccess() {
        let player = this.getPlayer();
        if (player) {
            return player.accessLevel === AccessLevel.MODERATOR || player.accessLevel === AccessLevel.ADMIN || player.accessLevel === AccessLevel.GOD;
        }
        return false;
    }

    public static hasAdminAccess() {
        let player = this.getPlayer();
        if (player) {
            return player.accessLevel === AccessLevel.ADMIN || player.accessLevel === AccessLevel.GOD;
        }
        return false;
    }

    public static getCampaignTypes() {
        return PlayerStore.state.campaignTypes;
    }

    public static getUnseenCampaign() : CampaignType | undefined {
        return  PlayerStore.state.unseenCampaign;
    }

    public static getReferralBonus() : ReferralBonus | undefined {
        return  PlayerStore.state.referralBonus;
    }

    public static haveAnyReferralBonus():boolean{
        let refBonus = PlayerStore.state.referralBonus;
        return  !!refBonus && refBonus.startBonus + refBonus.refClanBonus + refBonus.refBonus > 0;
    }

    public static getCoordinate() {
        return PlayerStore.state.player!.coordinate;
    }

    public static getNeedDownload() {
        return PlayerStore.state.needDownload;
    }

    public static getQuality() {
        return PlayerStore.state.quality;
    }

    public static getArtifactIdsToLoad() {
        return PlayerStore.state.artifactIdsToLoad;
    }

    public static getArtifactImageMaxStair() {
        return PlayerStore.state.artifactImageMaxStair;
    }

    public static getPlayer() {
        return PlayerStore.state.player!;
    }

    public static getCombativeCreatures() {
        if (PlayerStore.state.player!.creatures) {
            return PlayerStore.state.player!.creatures.filter(c => c.combative);
        }
        return [];
    }

    public static getHeader() {
        return PlayerStore.state.header;
    }

    public static getFooter() {
        return PlayerStore.state.footer;
    }

    public static getPrompt() {
        return PlayerStore.state.prompt;
    }

    public static currentPlayerId() {
        return PlayerStore.state.player && PlayerStore.state.player.id;
    }
    public static setHistoryCategory(category: LogType | undefined) {
        PlayerStore.state.historyCategory = category;
    }

    public static getHistoryCategory() {
        return PlayerStore.state.historyCategory;
    }
    /**
     * returns delta between server and client time
     */
    public static getTimeDeltaMs() {
        return PlayerStore.state.timeDeltaMs;
    }

    public static getClientUuid() {
        return PlayerStore.state.clientUuid;
    }

    public static getGameStart() {
        return PlayerStore.state.gameStart;
    }

    public static findCreatureById(id: number) {
        let p = PlayerStore.getPlayer();
        if (p) {
            for (let c of p.creatures) {
                if (c.id === id) {
                    return c;
                }
            }
        }
        return null;
    }

    public static isEnoughResources(amount: Amount) {
        if (!PlayerStore.state.player) {
            return false;
        }
        return PlayerStore.state.player.mithril >= amount.mithril
            && PlayerStore.state.player.crystal >= amount.crystal
            && PlayerStore.state.player.elixir >= amount.elixir
            && PlayerStore.state.player.gold >= amount.gold
            && PlayerStore.state.player.sulfur >= amount.sulfur;
    }

    public static getResourceValue(resourceType: ResourceType) {
        switch (resourceType) {
            case ResourceType.GOLD:
                return PlayerStore.state.player!.gold;
            case ResourceType.CRYSTAL:
                return PlayerStore.state.player!.crystal;
            case ResourceType.ELIXIR:
                return PlayerStore.state.player!.elixir;
            case ResourceType.MITHRIL:
                return PlayerStore.state.player!.mithril;
            case ResourceType.SULFUR:
                return PlayerStore.state.player!.sulfur;
            case ResourceType.CAVIAR:
                return PlayerStore.state.player!.caviar;
            case ResourceType.EVENT_CURRENCY:
                return TaskUtils.getEventCurrency();

        }
        return 0;
    }

    public static getMoveSpeedMode() {
        return PlayerStore.state.player!.moveSpeedMode;
    }

    public static getSoundMute() {
        return PlayerStore.state.player!.soundMute;
    }

    public static getMailOrPhone() {
        return PlayerStore.state.player!.mailOrPhone;
    }

    //
    //
    // ================= Setters =================
    //
    //

    public static RESET_PROMPT() {
        PlayerStore.state.prompt = undefined;
    }

    public static SET_SPECIAL_PLACES_REQUESTED() {
        if (PlayerStore.state.player) {
            PlayerStore.state.player.requestSpecialPlaces = false;
        }
    }


    public static SET_CAMPAIGN_TYPES(campaignTypes: CampaignType[]) {
        PlayerStore.state.campaignTypes = campaignTypes;
    }

    public static SET_FORCE_DESCRIPTIONS(descriptions: ForceDescription[]) {
        if(!PlayerStore.state.forceDescriptions){
            PlayerStore.state.forceDescriptions = new Map<WarriorClass, ForceDescription>();
        }
        if(descriptions){
            descriptions.forEach(d => {
               if(!PlayerStore.state.forceDescriptions!.has(d.warriorClass)){
                   PlayerStore.state.forceDescriptions!.set(d.warriorClass, d);
               }
            });
        }
    }

    public static GET_FORCE_DESCRIPTION(warriorClass: WarriorClass) : ForceDescription | undefined {
        if(!PlayerStore.state.forceDescriptions){
            return undefined;
        }
        return PlayerStore.state.forceDescriptions!.get(warriorClass)!;
    }

    public static SET_UNSEEN_CAMPAIGN(unseenCampaign: CampaignType | undefined) {
        PlayerStore.state.unseenCampaign = unseenCampaign;
    }

    public static SET_REFERRAL_BONUS(referralBonus: ReferralBonus | undefined) {
        PlayerStore.state.referralBonus = referralBonus;
    }

    public static SET_HAMMERS_COUNT(hammersCount: number) {
        if(!PlayerStore.state.hammerDto){
            PlayerStore.state.hammerDto = new HammerDto();
        }
        PlayerStore.state.hammerDto!.currentCount = hammersCount;
    }

    public static SET_MAX_HAMMERS_COUNT(maxCount: number) {
        if(!PlayerStore.state.hammerDto){
            PlayerStore.state.hammerDto = new HammerDto();
        }
        PlayerStore.state.hammerDto!.maxCount = maxCount;
    }

    public static GET_HAMMERS_COUNT() {
        return PlayerStore.state.hammerDto!.currentCount;
    }

    public static GET_MAX_HAMMERS_COUNT() {
        return PlayerStore.state.hammerDto!.maxCount;
    }

    public static SET_CARD_STATE(cardState: CardState) {
        PlayerStore.state.cardState = cardState;
    }

    public static SET_SALE(sale: Sale) {
        PlayerStore.state.sale = sale;
    }

    public static GET_CARDS_STATE() {
        return PlayerStore.state.cardState;
    }

    public static GET_SALE() {
        return PlayerStore.state.sale;
    }

    public static RESET_REFERRAL_BONUS() {
        let refBonus = PlayerStore.state.referralBonus;
        if(refBonus){
            refBonus.refBonus = 0;
            refBonus.refClanBonus = 0;
            refBonus.startBonus = 0;
        }
    }

    public static SET_PLAYER(player: Player) {
        let sessionCreated = !PlayerStore.state.player && player;
        PlayerStore.doSetPlayer(player);

        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
        if (sessionCreated) {
            ListenerManager.callSessionCreateCallback();
        }

        if(player.metaData){
            let parts = player.metaData.split("|");
            if(parts[0] == MetaDataType.APP_METRICA_LOG.toString()){
                AppmetricaService.reportEventStatic(parts[1], JSON.parse(parts[2]));
            }
            player.metaData = "";
        }
    }

    public static SET_PLAYER_SKIP_CR(player: Player) {
        let sessionCreated = !PlayerStore.state.player && player;
        PlayerStore.doSetPlayerSkipCreatures(player);

        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
        if (sessionCreated) {
            ListenerManager.callSessionCreateCallback();
        }
    }

    public static SET_MARKER(marker: string) {
        if (PlayerStore.state.player && PlayerStore.state.player.playerMarkers) {
            let pm = new PlayerMarker();
            pm.playerId = PlayerStore.state.player.id;
            pm.playerMarker = marker;
            PlayerStore.state.player.playerMarkers.push(pm);
        }
    }

    public static REMOVE_MARKER(marker: string) {
        if (PlayerStore.state.player && PlayerStore.state.player.playerMarkers) {
            let markerIndex = -1;
            PlayerStore.getPlayer()!.playerMarkers.some((m: PlayerMarker, index) => {
                if (m.playerMarker == marker) {
                    markerIndex = index;
                }
            })
            if (markerIndex > -1) {
                PlayerStore.state.player.playerMarkers.splice(markerIndex, 1);
            }
        }
    }

    public static SET_PLAYER_SILENT(player: Player) {
        PlayerStore.doSetPlayer(player);
    }

    public static SET_CLAN_ID(clanId: number | undefined) {
        PlayerStore.state.player!.clanId = clanId;
    }

    public static SET_COORDINATE(coordinate: number) {
        PlayerStore.state.player!.coordinate = coordinate;
    }

    public static TURN_OFF_GIFT_NEWS() {
        PlayerStore.state.player!.giftNews = GiftNews.NO_NEWS;
    }

    public static SET_BALANCE(balance: Balance, notifyAll: boolean = true) {
        PlayerStore.state.player!.gold = balance.gold;
        PlayerStore.state.player!.crystal = balance.crystal;
        PlayerStore.state.player!.elixir = balance.elixir;
        PlayerStore.state.player!.mithril = balance.mithril;
        PlayerStore.state.player!.sulfur = balance.sulfur;
        PlayerStore.state.player!.caviar = balance.caviar;

        for (let t of PlayerStore.state.player!.tasks) {
            for (let tp of balance.tasks) {
                if (t.id === tp.id) {
                    t.progress = tp.progress;
                }
            }
        }
        PlayerStore.state.player!.haveCompletedTasks = balance.haveCompletedTasks;

        if (notifyAll || balance.haveCompletedTasks) {
            ListenerManager.getPlayerListeners().forEach(l => l.onChange());
        } else {
            ListenerManager.getBalanceListeners().forEach(l => l.onBalanceChange());
        }
    }

    public static INC_GOLD(delta: number) {
        PlayerStore.state.player!.gold = PlayerStore.state.player!.gold + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static INC_CRYSTAL(delta: number) {
        PlayerStore.state.player!.crystal = PlayerStore.state.player!.crystal + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static INC_ELIXIR(delta: number) {
        PlayerStore.state.player!.elixir = PlayerStore.state.player!.elixir + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static INC_GEMS(delta: number) {
        PlayerStore.state.player!.mithril = PlayerStore.state.player!.mithril + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static INC_GOOD_POINTS(delta: number) {
        PlayerStore.state.player!.goodPoints = PlayerStore.state.player!.goodPoints + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }
    public static INC_EVIL_POINTS(delta: number) {
        PlayerStore.state.player!.evilPoints = PlayerStore.state.player!.evilPoints + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static INC_TICKETS(delta: number) {
        PlayerStore.state.player!.tickets = PlayerStore.state.player!.tickets + delta;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }
    public static INC_EXP(delta: number) {
        PlayerStore.state.player!.currentExp = Math.min(PlayerStore.state.player!.maxExp, PlayerStore.state.player!.currentExp + delta);
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static SET_TIME_DELTA_MS(timeDeltaMs: number) {
        PlayerStore.state.timeDeltaMs = timeDeltaMs;
    }

    public static SET_GAME_START() {
        PlayerStore.state.gameStart = Date.now();
    }

    public static SET_CLIENT_UUID(uuid: string) {
        PlayerStore.state.clientUuid = uuid;
    }

    public static SET_ADMINED(admined: boolean) {
        PlayerStore.state.admined = admined;
    }

    public static SET_VERSION(version: number) {
        PlayerStore.state.version = version;
    }

    public static SET_ARTIFACT_IDS(artifactIdsToLoad: ArtifactId[], artifactImageMaxStair: number) {
        PlayerStore.state.artifactIdsToLoad = artifactIdsToLoad;
        PlayerStore.state.artifactImageMaxStair = artifactImageMaxStair;
    }

    public static SET_NEED_DOWNLOAD_IMAGES(needDownload: boolean) {
        PlayerStore.state.needDownload = needDownload;
    }

    public static SET_HEADER(header: string) {
        PlayerStore.state.header = header;
    }

    public static SET_FOOTER(footer: string) {
        PlayerStore.state.footer = footer;
    }

    public static SET_HAVE_NEW_MAIL() {
        PlayerStore.state.player!.haveNewMailMessage = true;
        ListenerManager.getPlayerListeners().forEach(l => l.onChange());
    }

    public static SET_OFFER_SEEN() {
        if (PlayerStore.state.player!.offers) {
            for (let offer of PlayerStore.state.player!.offers) {
                offer.viewed = true;
            }
        }
    }

    public static SET_CLAN_STATE(clanParticipationState: ClanParticipationState) {
        PlayerStore.state.player!.clanParticipationState = clanParticipationState;
    }

    public static SET_PROMPT_CLAN(promptClan: boolean) {
        PlayerStore.state.player!.promptClan = promptClan;
    }

    public static SET_HAVE_NEW_GIFT(haveNewGift: boolean) {
        PlayerStore.state.player!.haveNewGift = haveNewGift;
    }

    //
    //
    // ================= Actions =================
    //
    //

    public static fetchPlayerInfoCurrent(init: boolean = false): Promise<void> {
        return http.get('/api/player/info/current?frontVersion=' + String(Version.VERSION_NUM) + '&init=' + String(init))
            .then(r => r.data)
            .then(playerInfoObj => {
                PlayerStore.handlePlayerInfo(playerInfoObj, true);
            }).catch(function (error) {
                if (error.response.status == 401) {
                    // do nothing
                } else {
                    throw error;
                }
            });
    }

    public static fetchPlayerId(domain: string): Promise<number> {
        return http.get(domain + '/api/player/id/current')
            .then(r => r.data)
            .then(obj => {
                return obj;
            });
    }

    public static fetchCampaignStatus( type: CampaignType): Promise<StatusDto> {
        return http.get('/api/campaign/get/status/' + type)
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new StatusDto(), obj);
            });
    }

    public static fetchOkGroupCampaign(): Promise<OkGroupCampaignDto> {
        return http.get('/api/campaign/get/ok/group')
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new OkGroupCampaignDto(), obj);
            });
    }

    public static fetchChestCampaignStatus(): Promise<StatusDto> {
        return http.get('/api/campaign/get/chest/status')
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new StatusDto(), obj);
            });
    }

    public static fetchChest2CampaignStatus(): Promise<StatusDto> {
        return http.get('/api/campaign/get/chest2/status')
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new StatusDto(), obj);
            });
    }

    public static fetchChest3CampaignStatus(): Promise<StatusDto> {
        return http.get('/api/campaign/get/chest3/status')
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new StatusDto(), obj);
            });
    }

    public static fetchArtCampaignStatus(): Promise<StatusDto> {
        return http.get('/api/campaign/get/art/status')
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new StatusDto(), obj);
            });
    }

    public static fetchPortalDiscounts(): Promise<number[]> {
        return http.get('/api/campaign/get/portal/discounts')
            .then(r => r.data)
            .then(obj => {
                let res: number[] = Object.assign([], obj);
                return res;
            });
    }

    public static fetchWheelCampaignStatus(): Promise<WheelStatusDto> {
        return  http.get('/api/campaign/get/wheel/status')
            .then(r => r.data)
            .then(obj => {
                let dto = Object.assign(new WheelStatusDto(), obj);

                let player: Player = Object.assign(new Player(), dto.player);
                PlayerStore.SET_PLAYER(player);
                return dto;
            });
    }

    public static spinWheel(): Promise<WheelStatusDto> {
        return  http.get('/api/campaign/spin/wheel')
            .then(r => r.data)
            .then(obj => {
                let dto = Object.assign(new WheelStatusDto(), obj);
                let player: Player = Object.assign(new Player(), dto.player);
                if(!player.creatures){
                    console.error("Wrong response for spin wheel");
                    throw new Error("Wrong response for spin wheel");
                }

                PlayerStore.SET_PLAYER(player);
                return dto;
            }).catch(error => {
                console.error('Error on spin wheel:', error)
            });
    }

    public static isWheelAutoRotate() : boolean{
        return this.state.wheelAutoRotation;
    }

    public static setWheelAutoRotate(value:boolean){
        this.state.wheelAutoRotation = value;
    }

    public static takeRewardFromWheel(optionalCreatureId:number | null): Promise<WheelStatusDto> {
        return  http.get('/api/campaign/wheel/take/reward' + (optionalCreatureId ? '?optionalCreatureId=' + String(optionalCreatureId) : ''))
            .then(r => r.data)
            .then(obj => {
                let dto = Object.assign(new WheelStatusDto(), obj);
                let player: Player = Object.assign(new Player(), dto.player);
                if(!player.creatures){
                    console.error('Wrong response for take award from wheel')
                    throw new Error("Wrong response for take award from wheel");
                }

                PlayerStore.SET_PLAYER(player);
                return dto;
            }).catch(error => {
                console.error('Error on take award from wheel:', error)
            });
    }

    public static useCampaign(campaignId: number): Promise<void> {
        return http.get('/api/campaign/use/' + String(campaignId))
            .then(r => r.data)
            .then((balanceObj) => {
                let balance: Balance = Object.assign(new Balance(), balanceObj);
                PlayerStore.SET_BALANCE(balance);
            });
    }

    public static takeOkDaily(): Promise<void> {
        return http.get('/api/daily/ok/take')
            .then(r => r.data)
            .then((balanceObj) => {
                let balance: Balance = Object.assign(new Balance(), balanceObj);
                PlayerStore.SET_BALANCE(balance);
            });
    }

    public static takeFotoGift(): Promise<void> {
        return http.get('/api/integration/foto/gift/accept')
            .then(r => r.data)
            .then((balanceObj) => {
                PlayerStore.TURN_OFF_GIFT_NEWS();
                let balance: Balance = Object.assign(new Balance(), balanceObj);
                PlayerStore.SET_BALANCE(balance);
            });
    }

    public static takeMystoreGift(): Promise<void> {
        return http.get('/api/integration/mystore/gift/accept')
            .then(r => r.data)
            .then((balanceObj) => {
                PlayerStore.TURN_OFF_GIFT_NEWS();
                let balance: Balance = Object.assign(new Balance(), balanceObj);
                PlayerStore.SET_BALANCE(balance);
            });
    }

    public static takeOwnPromoGift(): Promise<void> {
        return http.get('/api/player/promo/gift/accept')
            .then(r => r.data)
            .then((balanceObj) => {
                PlayerStore.TURN_OFF_GIFT_NEWS();
                let balance: Balance = Object.assign(new Balance(), balanceObj);
                PlayerStore.SET_BALANCE(balance);
            });
    }

    public static saveYaPromocode(signature: string): Promise<YaPromocodeResult> {
        console.log("Ya Promo Signature = " + signature);
        return http.get('/api/integration/ya/promocode/' + signature + '/save')
            .then(r => r.data)
            .then((yaPromocodeResultObj) => {
                let yaPromocodeResult = Object.assign(new YaPromocodeResult(), yaPromocodeResultObj);
                PlayerStore.handlePlayerInfo(yaPromocodeResult.player, false);
                return yaPromocodeResult;
            });
    }

    public static fetchPlayerInfo(artifactFrom: number, artifactTo: number): Promise<void> {
        return http.get('/api/player/info?artifactFrom=' + artifactFrom + '&artifactTo=' + artifactTo)
            .then(r => r.data)
            .then(playerInfoObj => {
                PlayerStore.handlePlayerInfo(playerInfoObj, false);
            });
    }

    public static fetchPlayer(): Promise<number> {
        return http.get('/api/player/current')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static fetchPlayerSilent(): Promise<number> {
        return http.get('/api/player/current')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER_SILENT(player);
                return player;
            });
    }

    public static setCoordinate(coordinate: number): Promise<number> {
        const params = new URLSearchParams();
        params.append('coordinate', String(Math.floor(coordinate)));
        params.append('defaultWorld', this.state.player!.defaultWorld);
        params.append('worldType', this.state.player!.worldType);

        return http.post('/api/player/v2/coordinate/set', params)
            .then(r => r.data)
            .then((actualCoordinate) => {
                PlayerStore.SET_COORDINATE(actualCoordinate);
                return actualCoordinate;
            });
    }

    public static setMarker(marker: string) {
        const params = new URLSearchParams();
        params.append('marker', marker);

        return OnceCaller.doOnceWithPromise("set_marker_"+marker, () => {
            return http.post('/api/marker/add', params)
                .then(r => r.data)
                .then(playerObj => {
                    let player = Object.assign(new Player(), playerObj);
                    PlayerStore.SET_PLAYER(player);
                    return player;
                });
        });
    }

    public static setMarkers(markers: string[]) {
        const params = new URLSearchParams();
        params.append('markers', markers.toString());

        return http.post('/api/marker/addAll', params)
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static removeMarker(marker: string) {
        const params = new URLSearchParams();
        params.append('marker', marker);

        return http.post('/api/marker/remove', params)
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static fetchRestoreHealthOptions(): Promise<RestoreOptions> {
        return http.get('/api/player/health/options')
            .then(r => r.data)
            .then(obj => {
                return Object.assign(new RestoreOptions(), obj);
            });
    }

    public static restoreHealth(payType: PayType): Promise<Player> {
        return http.post('/api/player/health/' + String(payType) + '/restore')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static takeEverydayReward(): Promise<Player> {
        return http.post('/api/player/everyday/reward/take')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static log(message: string): Promise<number> {
        return http.get('/api/dev/log?message=' + message);
    }

    public static changeMoveSpeedMode(): Promise<Player> {
        return http.post('/api/player/updateMoveSpeedMode')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static changeSoundMute(): Promise<Player> {
        return http.post('/api/player/updateSoundMute')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static switchUi(callback: (mode: InterfaceMode) => unknown) {
        http.post('/api/player/ui/switch')
            .then(r => r.data)
            .then(() => {
                let p: Player = PlayerStore.state.player!;
                if (p.interfaceMode === InterfaceMode.MODERN) {
                    PlayerStore.state.player!.interfaceMode = InterfaceMode.ORIGINAL;
                } else {
                    PlayerStore.state.player!.interfaceMode = InterfaceMode.MODERN;
                }
                callback(PlayerStore.state.player!.interfaceMode);
            });
    }

    public static switchPushMode(callback: () => unknown) {
        http.post('/api/player/push/switch')
            .then(r => r.data)
            .then((playerObj) => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                callback();
            });
    }

    public static setupQuality(quality: Quality) {
        http.post('/api/player/set/quality/' + String(quality))
            .then(r => r.data)
            .then(() => {
                window.location.reload();
            });
    }

    public static switchLang(callback: () => unknown) {
        http.post('/api/player/lang/switch')
            .then(r => r.data)
            .then(() => {
                let p: Player = PlayerStore.state.player!;
                if (p.lang === Lang.RU) {
                    PlayerStore.state.player!.lang = Lang.EN;
                    Settings.setEn()
                } else {
                    PlayerStore.state.player!.lang = Lang.RU;
                    Settings.setRu();
                }
                callback();
            });
    }

    public static setLang(callback: () => unknown, langCode: string, updateProfile: boolean = true) {
        if (updateProfile) {
            http.post(`/api/player/lang/switch/${langCode.toLowerCase()}`);
        }
        if (langCode.toLowerCase() === 'ru') {
            Settings.setRu();
        } else if (langCode.toLowerCase() === 'en') {
            Settings.setEn()
        }
        callback();
    }


    public static setOfferSeen(): Promise<void> {
        return http.post('/api/offer/set/seen')
            .then(() => {
                PlayerStore.SET_OFFER_SEEN();
            });
    }

    public static logClientError(message: string): Promise<void> {
        const params = new URLSearchParams();
        params.append('message', message);
        params.append('domain', window.location.href);
        return http.post('/api/log/add', params);
    }

    public static resetRoadNews(): Promise<Player> {
        return http.post('/api/player/news/road/reset')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static resetNotification(): Promise<Player> {
        return http.post('/api/player/notification/reset')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static getCustomNews(): Promise<string> {
        return http.get('/api/news/get/last')
            .then(r => r.data)
            .then(data => {
                return data;
            });
    }

    public static getManual(id: string): Promise<string> {
        return http.get('/api/manual/' + id + '/get')
            .then(r => r.data)
            .then(data => {
                return data;
            });
    }

    public static takeReferralBonus(): Promise<Player> {
        return http.get('/api/referral/bonus/take')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                PlayerStore.RESET_REFERRAL_BONUS();
                return player;
            });
    }

    public static getMyRefCode(): Promise<string> {
        return http.get('/api/referral/my/code')
            .then(r => r.data)
            .then((data) => {
                return data;
            });
    }

    public static getForceDescription(warriorClass: WarriorClass): Promise<ForceDescription> {
        return http.get('/api/manual/force/' + warriorClass + '/get')
            .then(r => r.data)
            .then(data => {
                let forceDesc = Object.assign(new ForceDescription(), data);
                if(forceDesc){
                    PlayerStore.SET_FORCE_DESCRIPTIONS([forceDesc]);
                }
                return forceDesc;
            });
    }

    public static getForcesShortDescriptions(): Promise<ForceShortDescription[]> {
        return http.get('/api/manual/forces/short/description/get')
            .then(r => r.data)
            .then(listObj => {
                let forcesShortDesc: ForceShortDescription[] = Object.assign([], listObj);
                return forcesShortDesc;
            });
    }

    public static resetCreatureNews(): Promise<Player> {
        return http.post('/api/player/news/creature/reset')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static playerAction(playerId: number, action: PlayerAction): Promise<void> {
        switch (action) {
            case PlayerAction.BLOCK:
                return this.blockPlayer(playerId);
            case PlayerAction.UNBLOCK:
                return this.unblockPlayer(playerId);
        }
    }

    public static blockPlayer(playerId: number): Promise<void> {
        return http.post('/api/mail/block/' + String(playerId));
    }

    public static unblockPlayer(playerId: number): Promise<void> {
        return http.post('/api/mail/unblock/' + String(playerId));
    }

    public static logHidden(): Promise<void> {
        return http.get('/api/load/log/hidden');
    }

    public static logActive(): Promise<void> {
        return http.get('/api/load/log/active');
    }

    public static logLoadPreboot(): Promise<void> {
        return http.get('/api/load/log/preboot');
    }

    public static logLoadBoot(): Promise<void> {
        return http.get('/api/load/log/boot');
    }

    public static logLoadFive(): Promise<void> {
        return http.get('/api/load/log/five');
    }

    public static logLoadHalf(): Promise<void> {
        return http.get('/api/load/log/half');
    }

    public static logLoadCompleted(): Promise<void> {
        return http.get('/api/load/log/boot/completed');
    }

    public static logGameStarted(logHSesson: boolean): Promise<void> {
        let availableMemory;
        let usedMemory;
        let hSession = logHSesson ? HSessionUtils.getHSession() : undefined;
        try {
            // @ts-ignore
            availableMemory = performance.memory.jsHeapSizeLimit / 1000000; // will give you the JS heap size
            // @ts-ignore
            usedMemory = performance.memory.usedJSHeapSize / 1000000; // how much you're currently using
        } catch (e) {
            // do nothing
        }

        if (availableMemory && usedMemory && hSession) {
            return http.get('/api/load/v3/log/a/game/started?available=' + String(availableMemory) + '&used=' + String(usedMemory) + '&h_session=' + hSession);
        } else if (hSession) {
            return http.get('/api/load/v3/log/b/game/started?h_session=' + hSession);
        } else if (availableMemory && usedMemory) {
            return http.get('/api/load/v2/log/game/started?available=' + String(availableMemory) + '&used=' + String(usedMemory));
        } else {
            return http.get('/api/load/log/game/started');
        }
    }

    public static logUnload(loadedPct: number): Promise<void> {
        return http.get('/api/load/log/unload?loadedPct=' + String(loadedPct));
    }

    public static setRated(): Promise<void> {
        return http.get('/api/player/set/rated');
    }

    public static updateReloadVersion(): Promise<void> {
        return http.post('/api/player/version/update');
    }

    public static techEnter(id: number): Promise<void> {
        return http.get('/api/tech/player/enter/' + String(id));
    }

    public static techExit(): Promise<void> {
        return http.get('/api/tech/player/exit');
    }

    public static getHistory(playerId: number, page: number, filter?: string, subfilter?:string): Promise<HistoryDto> {
        return http.get('/api/tech/player/' + String(playerId) + '/log?page=' + String(page) +  (filter ? '&filter=' + filter : '') + (subfilter ? '&subfilter=' + subfilter : ''))
            .then(r => r.data)
            .then(rObj => {
                return Object.assign(new HistoryDto(), rObj);
            });
    }

    public static getPayments(playerId: number, page: number): Promise<PaymentsDto> {
        return http.get('/api/tech/player/' + String(playerId) + '/payments?page=' + String(page))
            .then(r => r.data)
            .then(rObj => {
                return Object.assign(new PaymentsDto(), rObj);
            });
    }

    public static getDelayedChest(): Promise<DelayedChestDto> {
        return http.get('/api/delayed/chest/ensure').then(r => r.data)
            .then(obj => {
                return Object.assign(new DelayedChestDto(), obj);
            });
    }

    public static speedupDelayedChest(): Promise<Player> {
        return http.get('/api/delayed/chest/speedup')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static takeDelayedChest(): Promise<Player> {
        return http.get('/api/delayed/chest/take')
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }

    public static getNotifs(): Promise<Notif[]> {
        return http.get('/api/player/notif/list')
            .then(r => r.data)
            .then(resObj => {
                let res: Notif[] = [];
                for (let obj of resObj) {
                    let sc = Object.assign(new Notif(), obj);
                    res.push(sc);
                }
                return res;
            });
    }

    public static deleteNotif(notifType: NotifType): Promise<Player> {
        return http.post('/api/player/notif/remove/' + notifType.toString())
            .then(r => r.data)
            .then(playerObj => {
                let player = Object.assign(new Player(), playerObj);
                PlayerStore.SET_PLAYER(player);
                return player;
            });
    }
}
